import { NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import translation from "../Translations/translation.json";

const FooterSub = () => {
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="section-bg">
      <div className="container">
        <div className="footer-pg footer-sub-pg">
          {" "}
          {/* <div style={{ textAlign: "center" }}>
            © All rights reserved 2024 - PoWDex.io
          </div> */}
          <div className="footer-flex bg-none ">
            {" "}
            <div className="mt-2 text-center">
              <NavLink
                to="https://x.com/army_pow?t=T0M2bZdKN-JJkwSlfoV-sA&s=08"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <i className="fa-brands fa-x-twitter fa-xl me-2"></i>
              </NavLink>
              <NavLink
                to="https://t.me/+BkN4uLHxcp9iZjc1"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <i className="fa-brands fa-telegram fa-xl ms-1"></i>
              </NavLink>{" "}
              <NavLink
                to="https://medium.com/@powarmy"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <i className="fa-brands fa-medium fa-xl ms-2"></i>
              </NavLink>{" "}
              <NavLink
                to="https://youtube.com/@army_pow?feature=shared"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <i className="fa-brands fa-youtube fa-xl ms-2"></i>
              </NavLink>
            </div>
            <div style={{ color: "grey" }}>© {translate("rights")}</div>
            {/* <div>
              <span className="me-2">Trademark Policy</span>
              <span> Privacy Policy</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSub;
