import React from "react";
import { useLanguage } from "../../LanguageContext";
import translation from "../../Translations/translation.json";
import { NavLink } from "react-router-dom";
import "./AllPlatform.css";

export const AllPlatform = () => {
  const { language } = useLanguage();
  const translateL = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="all-platform-pg">
      <div className="container">
        <div className="eth-form-products">
          <NavLink to="https://www.pow.army" target="_blank">
            {" "}
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1737548749/Group_95_n3hvia.svg"
              alt=""
            />{" "}
          </NavLink>
          <p className="mt-1" style={{ marginBottom: "0" }}>
            {translateL("ecosystem.heading")}
          </p>
          <p style={{ textAlign: "left", marginBottom: "20px" }}>
            {translateL("ecosystem.mission")}
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="footer-products-details  footer-products-center">
              <div className="powarmy-token">
                {/* <h6>PoWToken</h6> */}{" "}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1734166257/Group_85_pno6ls.svg"
                  alt=""
                />
                <NavLink to="https://www.powtoken.xyz" target="_blank">
                  <i className="fa-solid fa-up-right-from-square ms-1"></i>
                </NavLink>
                <p style={{ marginBottom: "0" }}>
                  {translateL("services.tokenCreator.heading")}
                </p>
                <p>{translateL("services.tokenCreator.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-products-details footer-products-center">
              <div className="powarmy-token">
                {/* <h6>PoWDex</h6> */}{" "}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1734153508/powdex_svg_165_ym6eui.svg"
                  alt=""
                />
                <NavLink to="/">
                  <i className="fa-solid fa-up-right-from-square ms-1"></i>
                </NavLink>{" "}
                <p style={{ marginBottom: "0" }}>
                  {" "}
                  {translateL("services.dex.heading")}
                </p>
                <p>{translateL("services.dex.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-products-details">
              <div className="powarmy-token">
                {" "}
                {/* <h6>PoWTools</h6> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1736488506/powtools_1_nkmpap.svg"
                  alt=""
                />
                <NavLink to="https://www.powtools.io" target="_blank">
                  <i className="fa-solid fa-up-right-from-square ms-1"></i>
                </NavLink>{" "}
                <p style={{ marginBottom: "0" }}>
                  {translateL("services.analytics.heading")}
                </p>
                <p>{translateL("services.analytics.description")}</p>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
