import axios from "axios";

const javaBaseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    return process.env.REACT_APP_API_DEV_JAVA_URL;
  } else if (process.env.REACT_APP_NODE_ENV === "production") {
    return process.env.REACT_APP_API_PROD_JAVA_URL;
  }
};

const nodeBaseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === "development")
    return process.env.REACT_APP_API_DEV_NODE_URL;
  if (process.env.REACT_APP_NODE_ENV === "production")
    return process.env.REACT_APP_API_PROD_NODE_URL;
};

const javaAPI = axios.create({
  baseURL: javaBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
});

const nodeAPI = axios.create({
  baseURL: nodeBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// NODE APIs
export const GetAllToken = (data) =>
  nodeAPI.get(
    `/api/token/allToken?size=${data.size}&page=${data.page}&filter=${data.filter}`
  );

// JAVA APIs
export const CreatePair = (data) => javaAPI.post("/Uniswap/newPair", data);
export const RemoveLiquidity = (data) =>
  javaAPI.post("/Uniswap/removeLiquidity", data);
export const SwapToken = (data) => javaAPI.post("/Uniswap/TokenBuySell", data);
export const ShowAllLiquidityPools = (walletAddress) =>
  javaAPI.get(`/Uniswap/getPooledDataByWalletAddress/${walletAddress}`);
