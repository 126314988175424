import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Swap from "./Components/Pages/Swap/Swap";
import Send from "./Components/Pages/Send/Send";
import Buy from "./Components/Pages/Buy/Buy";
import "./App.css";
import Token from "./Components/Pages/Token/Token";
import Pool from "./Components/Pages/Pool/Pool";
import ViewPositions from "./Components/Pages/ViewPositions/ViewPositions";
import CreatePositions from "./Components/Pages/CreatePositions/CreatePositions";
import Migrate from "./Components/Pages/Migrate/Migrate";
import RemoveLiquidity from "./Components/Pages/RemoveLiquidity/RemoveLiquidity";
import PoolTable from "./Components/Pages/PoolTable/PoolTable";
import Footer from "./Components/Footer/Footer";
import FooterSub from "./Components/Footer/FooterSub";

function App() {
  return (
    <div className="body-pg">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/send" element={<Send />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/send" element={<Send />} />
          <Route path="/tokens" element={<Token />} />
          {/* <Route path="/pool" element={<Pool />} /> */}
          <Route path="/pool/:walletAddress" element={<ViewPositions />} />
          <Route path="/createPool" element={<CreatePositions />} />
          <Route path="/removeLiquidity" element={<RemoveLiquidity />} />
          <Route path="/migrate" element={<Migrate />} />
          <Route path="/pooltable" element={<PoolTable />} />
          <Route path="/transactions" element={<PoolTable />} />
        </Routes>
        <FooterSub />
      </Router>
    </div>
  );
}

export default App;
