import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contract, formatEther, parseEther } from "ethers";
import { toast } from "react-toastify";
import { poolContract } from "../../../Ethereum/Constant/addresses";
import { poolABI, ERC20ABI } from "../../../Ethereum/Constant/abis";
import { contractInstance, signer } from "../../../Ethereum/Connect/config";
import Loading from "../../Loading/Loading";
import { useLanguage } from "../../LanguageContext";
import * as API from "../../../Utils/Services/api";
import translation from "../../Translations/translation.json";

const RemoveLiquidity = () => {
  const navigate = useNavigate();
  const [liquidityAddress, setLiquidityAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLiquidityRemoved, setShowLiquidityRemoved] = useState(false);
  const removeHandler = async () => {
    if (!sessionStorage.getItem("account")) {
      toast.error("Please connect metamask wallet");
      return;
    }
    try {
      setLoading(true);
      const contract = await contractInstance(liquidityAddress, poolABI);
      const liquidityTokenAddress = await contract.liquidityToken();
      const userSigner = await signer();
      const walletAddress = await userSigner.getAddress();
      const liquidityToken = new Contract(
        liquidityTokenAddress,
        ERC20ABI,
        userSigner
      );
      const lpBalance = await liquidityToken.balanceOf(userSigner);

      // console.log("lpBalance", formatEther(lpBalance));
      const formattedLpBalance = formatEther(lpBalance);

      toast.info("Transaction sent! Waiting for confirmation...");
      const tx = await contract.removeLiquidity(lpBalance);
      await tx.wait();

      const payload = {
        ethAmount: Number(formattedLpBalance),
        walletAddress,
        poolAddress: liquidityAddress,
      };

      const resp = await API.RemoveLiquidity(payload);

      if (resp.data.status) {
        toast.success("Liquidity pool removed!");
        setLiquidityAddress("");
        setShowLiquidityRemoved(true);
      } else {
        toast.error(resp.data.message);
        setShowLiquidityRemoved(false);
      }
      setLoading(false);
    } catch (err) {
      console.log("ERROR:", err.reason);
      // toast.error("Please enter valid liquidity contract address");
      toast.error(err.reason);
      setLoading(false);
      setShowLiquidityRemoved(false);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  const closeModal = () => {
    setShowLiquidityRemoved(false);
  };
  return (
    <div className=" view-positions-pg add-liquidity-pg remove-liquidity-page">
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>{" "}
      <div className="container">
        <div className="page-width-vp">
          <div className="createpositons-pg remove-liquidity-pg ">
            <div className="migrate-pg-heading">
              <div>
                <i onClick={handleGoBack} className="fa fa-arrow-left"></i>
              </div>
              <div className="add-liquidity-heading">
                {translate("removeLiquidity.heading")}
              </div>
              <div></div>
            </div>
            <div className="migrate-pg-body">
              <p>{translate("removeLiquidity.enterAddress")}</p>
              <div className="div-add-positons-btn">
                <div className="add-postions-form-btn selected-token-btn">
                  <input
                    type="text"
                    name="userToken"
                    className="form-control search-input-box search-input-box-liqui pt-2 pb-2"
                    value={liquidityAddress}
                    placeholder={translate("removeLiquidity.enterAddress")}
                    onChange={(e) => setLiquidityAddress(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="get-started-btn mt-4 remove-btn">
                <button
                  className="text-white "
                  onClick={removeHandler}
                  disabled={loading}
                >
                  {loading ? <Loading /> : translate("removeLiquidity.remove")}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>{" "}
        {showLiquidityRemoved && (
          // <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div
            className="modal modal-backcss show d-block"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog   modal-dialog-centered"
              role="document"
            >
              <div className="modal-content text-center">
                <div className="modal-header">
                  <h5 className="modal-title" style={{ color: "#ebebeb" }}>
                    Liquidity Remove
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <p style={{ marginBottom: "0", color: "#fefefe" }}>
                    Liquidity removed successfully!
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    style={{
                      backgroundColor: "#00a8e8",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                    type="button"
                    className="btn"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoveLiquidity;
