import { NavLink } from "react-router-dom";
import Web3 from "web3";
import { useLanguage } from "../LanguageContext";
import translation from "../Translations/translation.json";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Navbar.css";

const Navbar = () => {
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [network, setNetwork] = useState(null);
  useEffect(() => {
    const storedAccount = sessionStorage.getItem("account");
    const storedNetwork = sessionStorage.getItem("network");

    if (storedAccount && storedNetwork) {
      setAccount(storedAccount);
      setNetwork(storedNetwork);
    }
  }, []);
  const handleConnectMetamask = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const accounts = await web3Instance.eth.getAccounts();
        setAccount(accounts[0]);
        // console.log("Connected Account:", accounts[0]);

        const networkId = await web3Instance.eth.net.getId();
        // console.log("Network ID:", networkId);
        setNetwork(networkId);

        sessionStorage.setItem("account", accounts[0]);
        sessionStorage.setItem("network", networkId);
        toast.success(`${translate("metamaskConnected")}`);
      } catch (error) {
        console.error("Connection error:", error);
      }
    } else {
      toast.error(`${translate("metamaskNotInstalled")}`);
      // console.log("Metamask not installed");
    }
  };
  // const handleDisconnectMetamask = () => {
  //   setAccount(null);
  //   setNetwork(null);
  //   localStorage.removeItem("account");
  //   localStorage.removeItem("network");
  //   console.log("Wallet disconnected");
  // };
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 5)}.....${address.slice(-5)}`;
  };
  const { toggleLanguage, language } = useLanguage();
  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <>
      <nav className="navbar navbar-expand-md">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1734153508/powdex_svg_165_ym6eui.svg"
              alt=""
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <div className="nav-link nav-explore">
                  {translate("nav.trade.heading")}
                  <ul className="hover-nav">
                    <li>
                      <NavLink className="nav-link" to="/swap">
                        <i className="fa-solid fa-retweet me-1"></i>{" "}
                        {translate("nav.trade.options.swap")}
                      </NavLink>
                    </li>
                    {/* <li>
                    <NavLink className="nav-link" to="/limit">
                      <i className="fa-solid fa-chart-simple me-1"></i> Limit
                    </NavLink>
                  </li> */}
                    <li>
                      <NavLink className="nav-link" to="/send">
                        <i className="fa-solid fa-arrow-up-from-bracket me-1"></i>{" "}
                        {translate("nav.trade.options.send")}
                      </NavLink>
                    </li>{" "}
                    <li>
                      <NavLink className="nav-link" to="/buy">
                        <i className="fa-solid fa-wallet me-1"></i>{" "}
                        {translate("nav.trade.options.buy")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link nav-explore">
                  {translate("nav.explore.heading")}
                  <ul className="hover-nav">
                    <li>
                      <NavLink className="nav-link" to="/tokens">
                        {translate("nav.explore.options.tokens")}
                      </NavLink>
                    </li>
                    {/* <li>
                    <NavLink className="nav-link" to="/pooltable">
                      Pool
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/transactions">
                      Transactions
                    </NavLink>
                  </li> */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link nav-explore">
                  {translate("nav.pool.heading")}
                  <ul className="hover-nav">
                    <li>
                      <NavLink className="nav-link" to={`/pool/${account}`}>
                        {translate("nav.pool.viewPositions")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="/createPool">
                        {translate("nav.pool.createLiquidity")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="/removeLiquidity">
                        {translate("nav.pool.removeLiquidity")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <form
              className="d-flex ms-auto mobile-ui-form"
              role="search"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* <input
              className="form-control me-2 search-input-box"
              type="search"
              placeholder="Search tokens"
              aria-label="Search"
            /> */}
              <div
                className=" select-lang-btn"
                style={{
                  width: "max-content",
                  whiteSpace: "nowrap",
                }}
              >
                <div className="language-toggle">
                  <button
                    className={`language-button ${
                      language === "en" ? "active" : ""
                    }`}
                    onClick={() => toggleLanguage("en")}
                  >
                    EN
                  </button>
                  <button
                    className={`language-button language-btn-chinese ${
                      language === "zh" ? "active" : ""
                    }`}
                    onClick={() => toggleLanguage("zh")}
                  >
                    中
                  </button>
                </div>
              </div>
              <button
                onClick={handleConnectMetamask}
                className="btn btn-connect-wallet"
                type="submit"
              >
                {account ? shortenAddress(account) : translate("nav.connect")}
              </button>
            </form>
          </div>
        </div>
      </nav>
      <ToastContainer />
    </>
  );
};

export default Navbar;
